export default function Contact() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="max-w-[400px] text-justify mb-10">
        <span className="font-bold">
          For inquiries, collaborations, or connections.
        </span>{" "}
        Send a direct message to @violetdanse on
        <a
          href="https://instagram.com/violetdanse"
          target="_blank"
          rel="noreferrer"
          className="underline italic ml-2"
        >
          Instagram
        </a>
        {" "} or email to
        <a
          href="https://instagram.com/violetdanse"
          target="_blank"
          rel="noreferrer"
          className="underline italic ml-2"
        >
          violetdanse@gmail.com
        </a>.
      </div>
      <div className="italic text-justify text-sm">Response times vary.</div>
    </div>
  );
}
