import { anthology } from "../catalogue";

export default function Main() {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap w-full justify-around">
        {anthology.map((item) => {
          return (
            <div key={item.title} className="flex flex-col-reverse mt-10">
              <div className="my-4 w-[300px] h-[200px] flex items-start flex-col">
                <div className="font-bold">{item.title}</div>
                <div>{item.event}</div>
                <div className="text-xs mt-4">
                  <div className="">
                    Date: {item.date} {item.time}
                  </div>
                  <div className="">Style: {item.style}</div>
                  <div className="">Performer/s: {item.performer}</div>
                  <div className="">Location: {item.location}</div>
                  <div className="">Music: {item.music}</div>
                  <div className="">Production: {item.production}</div>
                </div>
              </div>
              {item.reel ? (
                <iframe
                  src={item.reel}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={item.title + " video"}
                />
              ) : (
                <div className="w-full bg-black/10 h-[150px] flex justify-center items-center">
                  <div className="text-black/40 italic">Event Coming Soon</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
