import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router, routes } from "./routes";
import logo from "./imgs/logo.png";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <div className="h-screen flex justify-content items-center relative p-20 w-full">
      <div className="flex flex-col w-[300px] justify-center">
        <a href="/">
          <img
            src={logo}
            alt="handwriting that says Violet Danse"
            className="w-[200px] h-auto mb-10"
          />
        </a>
        {routes.map(({ link, title }) => {
          const location = window.location.pathname          
          return (
            <a key={title} className="mb-8 flex" href={link}>
              {title}
              <div className="ml-2">{location === link && "—"}</div>
            </a>
          );
        })}
      </div>
      <div className="flex flex-col w-2/3 h-screen overflow-scroll">
        <RouterProvider router={router} />
      </div>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
