import { preEvents, postEvents } from "../catalogue";

export default function Main() {
  const currentDate = new Date();
  return (
    <div className="flex py-10">
      <div className="flex flex-wrap justify-center">
        {postEvents.map((eventItem) => {
          let eventDate = new Date(eventItem?.formatDate);
          const upcoming = eventDate > currentDate;

          const eventItemProps = { ...eventItem, eventDate, upcoming };
          return <EventItemBlock {...eventItemProps} />;
        })}
        <div className="w-full flex items-center justify-center">
          <div className="w-full border-b-2 -mt-2"></div>
          <div className="w-full text-center mb-4 text-xs text-black/40 italic">
            Intermission From 2018-2021.
          </div>
          <div className="w-full border-b-2 -mt-2"></div>
        </div>
        {preEvents.map((eventItem) => {
          let eventDate = new Date(eventItem?.formatDate);
          const upcoming = eventDate > currentDate;

          const eventItemProps = { ...eventItem, eventDate, upcoming };
          return <EventItemBlock {...eventItemProps} />;
        })}
      </div>
    </div>
  );
}

function EventItemBlock(eIProps) {
  return (
    <div
      key={eIProps.event}
      className={
        "mb-6 w-[250px] m-2 p-2 " + (eIProps.upcoming ? "" : "bg-black/5")
      }
    >
      <div className="flex justify-between">
        <div className="font-bold">{eIProps.event}</div>
        {eIProps.eventLink && (
          <a
            href={eIProps.eventLink}
            target="_blank"
            rel="noreferrer"
            className="border-[1px] text-center h-[16px] w-[16px] rounded-full text-[10px] border-black/30 text-black/30"
          >
            i
          </a>
        )}
      </div>
      <div className="">
        <div className="flex flex-col text-xs">
          <div className="">{eIProps.displayDate}</div>
          <div className="italic">{eIProps.style}</div>
        </div>
        <div className="text-black/50 italic pt-[4px] text-[10px]">
          <div>{eIProps.location}</div>
          <div>{eIProps.performer}</div>
          <div>{eIProps.production}</div>
        </div>
      </div>
    </div>
  );
}
