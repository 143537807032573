import mtg from "../imgs/home-bg.jpg";

export default function Main() {
  return (
    <div className="h-screen flex flex-col justify-center">
    <img src={mtg} className="mb-[2px] self-center" alt="violet dancing in black dress with blackout curtain behind them" />
    <div className="text-right text-[10px] text-black/40">Mind The Gap XXIII, Giving While Needing, Photo by Lynn Lane Photography</div>
  </div>
  );
}
