import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Main, Bio, Anthology, Events, Library, Contact } from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/about",
    element: <Bio />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/anthology",
    element: <Anthology />,
  },
  {
    path: "/library",
    element: <Library />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

export const routes = [
  { title: "About", link: "/about" },
  { title: "Anthology", link: "/anthology" },
  { title: "Events", link: "/events" },
  { title: "Library", link: "/library" },
  { title: "Contact", link: "/contact" },
];
